<template>
  <div class="addRoom">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加活动</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表单 -->
    <el-form label-width="80px">
      <el-form-item label="活动名称">
        <el-input
          v-model="formList.title"
          placeholder="请输入活动名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动时间:" prop="activityTime">
        <el-date-picker
          style="width: 400px"
          v-model="formList.activityTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="连住天数" v-if="type =='link'">
        <el-input
          v-model="formList.days"
          style="width: 300px; margin-right: 10px"
          min="2"
          type="number"
          @blur="blur"
          placeholder="请输入连住天数"
        ></el-input>
      </el-form-item>
      <el-form-item label="提前预订天数" v-else>
        <el-input
          v-model="formList.days"
          style="width: 300px; margin-right: 10px"
          min="2"
          type="number"
          @blur="blur"
          placeholder="请输入提前预订天数"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="限购数量" >
          <el-input v-model="formList.quotaCount" placeholder="请输入限购数量">
          </el-input>
        </el-form-item>
        <el-form-item label=""
        ><span>0为不限次数，1 为1次 2为2次 以此类推....</span></el-form-item
      > -->
      <el-form-item label="是否可用优惠券">
        <el-radio v-model="formList.isCoupon" :label="1">是</el-radio>
        <el-radio v-model="formList.isCoupon" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否可用会员等级">
        <el-radio v-model="formList.isUser" :label="1">是</el-radio>
        <el-radio v-model="formList.isUser" :label="0">否</el-radio>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
  <script>
const platform = [
  { value: 1, name: "小程序" },
  { value: 2, name: "H5" },
  { value: 3, name: "IOS" },
  { value: 4, name: "Android" },
  { value: 5, name: "PC" },
];
// const cityOptions = [1,2,3,4,5];
//   import { sendFile } from "../../api/sendFile";
//   import { hotelList, allHotel } from "../../api/hotelList";
import {
  addActive,
  getActiveDetail,
  addActiveReserve,
  getActiveDetailReserve,
} from "../../api/room";
//   import { supList } from "../../api/supList.js";
import SendImage from "../../components/sendImage.vue";
export default {
  name: "addActivityLinking",
  components: {
    SendImage,
  },
  data() {
    return {
      supplierOptions: [{ cateId: 0, cateName: "自营" }],
      checkAll: false,
      checked: [],
      checkVal: [],
      platform: platform,
      radio: "1",
      roomImg: [],
      hotelLists: [],
      sellWayList: [
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
      formList: {
        connectId: 0,
        title: "",
        beginTime: "",
        endTime: "",
        days: null,
        // quotaCount: "",
        isCoupon: 0,
        isUser: 0,
        activityTime: [],
        advanceId: 0,
      },
      unsubscribe: 1,
      integral: null,
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.$route.query.connectId) {
      this.formList.connectId = this.$route.query.connectId;
      this.getActivedetail();
    } else if (this.$route.query.advanceId) {
      this.formList.advanceId = this.$route.query.advanceId;
      this.getActivedetail();
    }
  },
  methods: {
    blur() {
      var reg =
        /^(([1-9]\d*)|([0][.]{1}[0-9]{0,2}[1-9]+)|([1-9]\d*[.]{1}[0-9]+))$/g;

      if (this.type == "link") {
        if (!reg.test(this.formList.days)) {
          this.formList.days = 2;
        }
        if (this.formList.days <= 1) {
          this.formList.days = 2;
        }
      } else {
        if (!reg.test(this.formList.days)) {
          this.formList.days = 1;
        }
        if (this.formList.days <= 0) {
          this.formList.days = 1;
        }
      }
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value)) {
        this.$message({
          type: "error",
          message: "请输入整数!",
        });
        this.integral = "";
        return false;
      }
    },
    async onSave() {
      this.formList.beginTime = this.formList.activityTime[0];
      this.formList.endTime = this.formList.activityTime[1];
      // delete this.formList.activityTime;
      console.log(this.formList);
      for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      let demaad = this.type == "link" ? addActive : addActiveReserve;
      const { data } = await demaad({
        ...this.formList,
      });
      console.log(data);
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    onInput1(value) {
      if (isNaN(value)) {
        this.$message({
          message: "请输入数字",
          type: "error",
        });
      }
    },
    async getActivedetail() {
      let demaad =
        this.type == "link" ? getActiveDetail : getActiveDetailReserve;
      let data = {};
      if (this.type == "link") {
        data = await demaad({ connectId: Number(this.formList.connectId) });
      } else {
        data = await demaad({ advanceId: Number(this.formList.advanceId) });
      }
      if (data.data.code == 0) {
        Object.keys(this.formList).forEach((key) => {
          this.formList[key] = data.data.data[key];
        });
        this.formList.activityTime = [
          data.data.data.beginTime,
          data.data.data.endTime,
        ];
      }
    },
    addImageStr(image) {
      this.formList.showImg = image;
      console.log(1);
    },
    addImageStrs(image) {
      this.roomImg = image;
      console.log(2);
    },
  },
};
</script>
  <style lang="less" scoped>
.addRoom {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    width: 800px;
    margin-top: 50px;
    .col,
    .col1 {
      display: inline-block;
      .el-form-item__content {
        width: 200px;
      }
    }
    .el-form-item__content {
      width: 500px;
    }
    .showImg {
      .el-form-item__content {
        display: flex;
        flex-wrap: wrap;
        .img {
          position: relative;
          height: 100px;
          width: 100px;
          margin: 20px 20px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 2px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          margin-top: 20px;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
      }
    }
  }
  .btn {
    width: 200px;
  }
}
</style>
  